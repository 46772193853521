import React, { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import _last from 'lodash/last'
import './runbook-search-tags.css'
import styled from 'styled-components'
import EditRunbookModal from 'pages/runbook/EditRunbook/EditRunbookModal'

interface IRunbookSearchTags {
  className?: string
  tags: string[]
  placeholder?: string
  prefix?: any
  type?: 'simple' | null
  readonly?: boolean
  isSearchBox?: boolean
  onRemoveTag: (tag: string) => void
  handleSaveTags?: (tags: string[]) => void
  onAddTag: (tag: string) => void
  suggestion: string[]
  onTagRefreshed: (tags: string[]) => void
}

const Tags = styled.div`
  display: flex;
  flex-flow: wrap;
`
const Tag = styled.div`
  padding: 5px;
  display: flex;
  border: 1px solid #dedede;
  justify-content: center;
  margin: 3px 0 3px 3px;
  align-items: center;
  border-radius: 4px;
  background: #22c7e3;

  span {
    font-size: 12px;
    font-weight: bold;
    margin-right: 5px;
    color: #fff;
    width: max-content;

    svg {
      margin-top: 4px;
    }
  }
`

const RunbookSearchTags: React.FC<IRunbookSearchTags> = (props): React.ReactElement => {
  const {
    className, tags: tagsArr, readonly, onRemoveTag, isSearchBox, prefix,
    suggestion, onAddTag, onTagRefreshed, placeholder, type, handleSaveTags
  } = props

  const [editModal, setEditModal] = useState(false)
  const [_suggestions, setSuggestion] = useState(suggestion)
  const [text, setText] = useState('')
  const [tags, setTags] = useState(tagsArr)
  const [showSuggesions, setShowSuggestions] = useState(false)
  const textRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setSuggestion(suggestion)
  }, [suggestion])

  useEffect(() => {
    setTags(tagsArr)
  }, [tagsArr])

  const handleClickRemove = (tag: string) => {
    if (type === 'simple') {
      const __tags = tags.slice()
      const index = __tags.findIndex(t => t === tag)
      __tags.splice(index, 1)
      setTags(__tags)
    } else {
      onRemoveTag(tag)
    }
  }

  const renderCloseIcon = (t: string) => {
    if (readonly) return null
    if (type === 'simple' && !editModal) return null
    return (<span className='action-icon' onClick={() => handleClickRemove(t)}>x</span>)
  }

  const renderTags = () => {
    return tags.map((t) => {
      return (
        <Tag key={t}>
          <span>{t}</span>
          {renderCloseIcon(t)}
        </Tag>
      )
    })
  }

  const handleSelectSuggestions = (s: string) => {
    setText(s)
    setShowSuggestions(false)
    textRef?.current?.focus()
  }

  const handleEnterKey = (event: any) => {
    if (event.key === 'Enter') {
      setText(event.target.innerText)
      setShowSuggestions(false)
      textRef?.current?.focus()
    }
  }

  const checkIfExists = (str: string) => {
    return tags.findIndex((t) => t.trim().startsWith(str))
  }

  const getFormattedText = (_text: string): any[] => {
    if (_text.trim().startsWith('@alert:')) {
      const isExist = checkIfExists('@alert:')
      if (isExist !== -1) {
        return [_text, isExist]
      }
      return [_text, -1]
    } else if (_text.trim().startsWith('@tags:')) {
      const isExist = checkIfExists('@tags:')
      if (isExist !== -1) {
        return [_text, isExist]
      }
      return [_text, -1]
    } else if (_text.trim().startsWith('@remediation:')) {
      const isExist = checkIfExists('@remediation:')
      if (isExist !== -1) {
        return [_text, isExist]
      }
      return [_text, -1]
    } else if (_text.trim().startsWith('@cause:')) {
      const isExist = checkIfExists('@cause:')
      if (isExist !== -1) {
        return [_text, isExist]
      }
      return [_text, -1]
    } else if (_text.trim().startsWith('@text:')) {
      const isExist = checkIfExists('@text:')
      if (isExist !== -1) {
        return [_text, isExist]
      }
      return [_text, -1]
    } else {
      const isExist = checkIfExists('@text:')
      if (isExist !== -1) {
        return [`@text:${text}`, isExist]
      }
      return [`@text:${text}`, -1]
    }
  }

  const renderInput = () => {
    if (readonly) return null
    if (type === 'simple' && !editModal && tags.length === 0)
      return <input
        className='simple-text-input'
        onClick={handleOpenModal}
        placeholder='Enter Tag'
        readOnly={true}
      />

    if (type === 'simple' && !editModal) return null

    return (
      <input
        className='search-tag-input'
        placeholder={placeholder || 'Enter tag'}
        value={text}
        autoFocus={type === 'simple'}
        ref={textRef}
        onKeyDown={handleKeyDown}
        onChange={({ currentTarget }) => setText(currentTarget.value)}
      />
    )
  }

  const renderSearchInputSection = () => (
    <div className='search-input-section'>
      {renderInput()}
      <div className='suggestion-section'>
        {renderSuggestions()}
      </div>
    </div>
  )

  const handleOpenModal = () => {
    if (type === 'simple' && !readonly) {
      setEditModal(true)
    } else {
      textRef?.current?.focus()
    }
  }

  const renderSuggestions = () => {

    if (text.length === 0 || _suggestions.length === 0 || !showSuggesions) {
      return null
    }

    return (
      <div className='suggestions'>
        {_suggestions.map((s) =>
          <span
            onClick={() => handleSelectSuggestions(s)}
            tabIndex={0}
            onKeyDown={handleEnterKey}
            key={s}
          >{s}
          </span>
        )}
      </div>
    )
  }

  const handleClose = () => {
    if (handleSaveTags) {
      handleSaveTags(tags)
      setEditModal(false)
    }
  }

  const handleKeyDown = useCallback((event: any) => {
    const tag = event.target.value?.trim() || ''
    if (type === 'simple' && event.key === 'Enter') {
      if (tags.findIndex(t => t === tag) !== -1) {
        setText('')
        return
      }
      setTags([...tags, tag]) // set in this scope
      setText('')
      return
    }

    if (event.key === 'Enter') {
      const [_text, index] = getFormattedText(event.target.value?.trim())
      if (index !== -1) {
        const __tags = tags.slice()
        __tags.splice(index, 1, _text)
        onTagRefreshed(__tags)
      } else {
        onAddTag(_text)
      }
      setText('')
    }

    if (event.key === 'Backspace' && !text && tags.length > 0) {
      if (type === 'simple') {
        setTags(tags.slice(0, tags.length - 1))
      } else {
        onRemoveTag(_last(tags) || '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, tags])

  useEffect(() => {
    if (text.length > 0) {
      setSuggestion(suggestion.filter((s) => s.toLowerCase().includes(text)))
      setShowSuggestions(true)
    } else {
      setSuggestion(suggestion)
      setShowSuggestions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text])

  return (
    <>
      {editModal && (
        <EditRunbookModal title={'Enter tags'} show={editModal} onClose={handleClose}>
          <div
            className={cx('search-tags-section', className || '', {
              'runbook-search-box': isSearchBox,
              'prefix': !!prefix
            })}
          >
            <Tags>
              {prefix || null}
              {renderTags()}
            </Tags>
            {renderSearchInputSection()}
          </div>
        </EditRunbookModal>
      )}

      {
        !editModal && (
          <div
            onClick={handleOpenModal}
            className={cx('search-tags-section', className || '', {
              'runbook-search-box': isSearchBox,
              'prefix': !!prefix
            })}
          >
            <Tags>
              {prefix ? <i className='prefix-icon'>{prefix}</i> : null}
              {renderTags()}
            </Tags>
            {renderSearchInputSection()}
          </div>
        )
      }
    </>
  )
}

export default RunbookSearchTags