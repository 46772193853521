import React, { useState } from 'react'
import RunbookSearchTags from '../../RunbookSearchTags'
import styled from 'styled-components'

const LayoutSection = styled.div`
  padding: 2rem;
`

const TestLayout = () => {

  const [tags, setTags] = useState<string[]>([])

  const onRemoveTag = (tag: string) => {
    const index = tags.findIndex(t => t === tag)
    const __tags = tags.slice()
    __tags.splice(index, 1)
    setTags(__tags)
  }

  const onTagRefreshed = (tags: string[]) => {
    setTags(tags)
  }

  return (
    <LayoutSection>
      <RunbookSearchTags
        suggestion={['@text:', '@remediation:', '@cause:', '@tags:', '@alert:']}
        tags={tags}
        onRemoveTag={onRemoveTag}
        onTagRefreshed={onTagRefreshed}
        onAddTag={(tag: string) => {
          setTags([...tags, tag])
        }}
      />
    </LayoutSection>
  )
}

export default TestLayout