import React from 'react'
import Tooltip from 'rc-tooltip'

interface NCTooltipI {
  overlay: string | React.ReactElement
  children: React.ReactElement
  animation?: string
  placement?: string
  trigger?: any
}

export const NCTooltip: React.FC<NCTooltipI> = (props) => {
  const {animation = 'zoom', placement, trigger = ['hover']} = props
  return (
    <Tooltip
      placement={placement}
      overlayClassName='tooltip-overlay'
      animation={animation}
      overlay={props.overlay}
      trigger={trigger}
    >
      {props.children}
    </Tooltip>
  )
}

export default NCTooltip
