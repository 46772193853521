import React, { ComponentType, lazy, Suspense } from 'react'

const loadable = (importFunc: any, {fallback = null} = {fallback: null}) => {
  const LazyComponent = lazy(importFunc)

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export const retry =
  (fn: any, retriesLeft = 5, interval = 1000): Promise<{ default: ComponentType<any> }> => {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error: any) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              // reject('maximum retries exceeded')
              reject(error)
              return
            }

            // Passing on "reject" is the important part
            retry(fn, interval, retriesLeft - 1).then(resolve, reject)
          }, interval)
        })
    })
  }

export default loadable