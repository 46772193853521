import React, { useRef, useState } from 'react'
import clsx from 'classnames'
import {
  ArrowDownIcon,
  LogoutIcon,
  SettingIcon,
  TeamRoleUserIcon,
  UserIcon,
} from 'components/ui/icons'
import useOutsideClick from './useOutsideClick'
import { useAuth } from 'common/hooks/use-simple-auth'
import { Link } from 'react-router-dom'

import {
  checkIfUserAllowForTeamAndRole,
  isUserAllowedToExtensions
} from 'common/utils/permissionFunctions'
import { ACCOUNT_SETTING_TAB } from 'store/reducers/profile.reducer'
import { ENV } from 'common/constants'

interface UserDropdownMenuI {
}

const UserDropdownMenu: React.FC<UserDropdownMenuI> = (props): React.ReactElement => {

  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const {logout, user} = useAuth()

  const toggleMenu = () => setOpen(!open)

  useOutsideClick(ref, () => {
    if (open) {
      setOpen(false)
    }
  })

  const getTeamAndRoleLink = () => {
    return `/users-roles/users`
  }

  const renderTeamAndRoleLinkByAuth = () => {
    const {permissions, user_role} = user
    if (!checkIfUserAllowForTeamAndRole(user_role, permissions))
      return null

    return (
      <li>
        <Link to={getTeamAndRoleLink()}>
          <i className='nc-svgIcon'>
            <TeamRoleUserIcon/>
          </i>&nbsp;
          Users and Groups
        </Link>
      </li>
    )
  }

  const Separator = (
    <li>
      <div className='separator'/>
    </li>
  )

  const renderMyProfileMenu = () => {
    return (
      <Link to={`/my-profile/${ACCOUNT_SETTING_TAB}`}>
        <i className='nc-svgIcon'>
          <UserIcon/>
        </i>&nbsp;
        My Profile
      </Link>
    )
  }

  const renderExtensionMenuByPermissions = () => {
    const {permissions, user_role} = user

    if (ENV === 'prod' || ENV === 'production')
      return null

    if (!isUserAllowedToExtensions(permissions, user_role)) {
      return null
    }
    return (
      <Link to={'/integrations'}>
        <i className='nc-svgIcon'>
          <SettingIcon/>
        </i>&nbsp;
        Integrations
      </Link>
    )
  }

  return (
    <ul className='g-icons-list' ref={ref} onClick={toggleMenu}>
      <li
        className={clsx('g-dropdown right', {
          open
        })}
      >
        <div
          id='profile-dropdown'
          className='header-right-block account_dropdown dropdown-toggle'
        >
          <div className='user-icon-container'>
            {
              <img
                src={user.user_profile_url || 'http://www.gravatar.com/avatar/e0026290dd527af8a4bfda00639ed886.jpg?s=100&d=mm'}
                style={{width: '35px', height: '35px'}}
                alt='avtar'
              />
            }
          </div>
          <div className='user-name'>
            {user.email}
            <small className='account-name'>
              {user.user_name || ''}&nbsp;({user?.user_role[0] || 'Custom permissions'})
            </small>
            <span>
               <ArrowDownIcon/>
            </span>
          </div>
        </div>
        <ul
          className={clsx(`g-dropdown-menu`, open ? 'd-block' : 'd-none')}
        >
          <li>
            <span>{user.email}</span>
          </li>
          {Separator}
          <li>
            {renderMyProfileMenu()}
          </li>
          {Separator}
          {renderTeamAndRoleLinkByAuth()}
          {Separator}
          <li>
            {renderExtensionMenuByPermissions()}
          </li>
          {Separator}
          <li className='nc-cursor-p' onClick={() => logout()}>
            <span>
              <i className='nc-svgIcon'>
                <LogoutIcon/>
              </i>
              &nbsp;Logout
            </span>
          </li>
        </ul>
      </li>
    </ul>
  )
}

export default UserDropdownMenu
