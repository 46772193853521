import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const TABS = [
  {title: 'Clients', className: 'red', key: 'clients', count: 0},
  {title: 'Teams', className: 'yellow', key: 'teams', count : 0},
  {title: 'Status', className: 'skyblue', key: 'status', count: 0},
  {title: 'Users', className: 'green', key: 'users', count: 0},
]

interface ReportsI {
 loading: boolean
  tabs: any[]
  data: {
    embed_url: string | null
  }
  filters: {
    tab: string
    client_id: string | null
    client_name: string | null
  }
}

let initialState: ReportsI = {
  loading: false,
  tabs: TABS,
  data: {
    embed_url: null
  },
  filters: {
    client_id: null,
    client_name: null,
    tab: 'clients'
  }
}


const ReportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setTabsData(state, action: PayloadAction<any[]>) {
      state.tabs = action.payload
    },
    setData(state, action: PayloadAction<string>) {
      state.data.embed_url = action.payload
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = {
        ...state.filters,
        ...action.payload
      }
    }
  }
})


export const { setLoading, setData, setFilters, setTabsData } = ReportSlice.actions

export default ReportSlice.reducer
