import { IncidentsI } from '../store.types'
import _unionBy from 'lodash/unionBy'
import _remove from 'lodash/remove'


export const mergeSelectedIncidents = (current: IncidentsI[], _new: IncidentsI[]) => {
  return _unionBy(current, _new, 'number')
}

export const removeSelectedIncidents = (current: IncidentsI[], removeIncidents: IncidentsI[]) => {
  const arr = current.slice()
  _remove(arr, i => removeIncidents.findIndex(o => o.number === i.number) >= 0)
  return arr
}

export const removeIncidentsByNumber = (incidents: IncidentsI[], incident_number: string[]) => {
  return _remove(incidents, o => !incident_number.includes(o.number.toString()))
}
