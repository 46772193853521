import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { INotification, INotificationStatus } from '../store.types'

interface IInitialState {
  data: INotification[]
  loading: boolean
  status: INotificationStatus
  filters: {
    pageid: number
    items: number
    hasMore: boolean
  }
}

const initialState: IInitialState = {
  status: {
    notificaiton_id: '',
    type: '',
    notification_count: 0
  },
  filters: {
    pageid: 1,
    items: 10,
    hasMore: true
  },
  data: [],
  loading: false
}

const NotificationsReducer = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setNotifications(state, action: PayloadAction<INotification[]>) {
      state.data = action.payload
    },
    addNotifications(state, action: PayloadAction<INotification[]>) {
      state.data = [...state.data, ...action.payload]
    },
    clearNotifications(state, action: PayloadAction<null>) {
      state.data = []
    },
    setStatus(state, action: PayloadAction<INotificationStatus>) {
      state.status = action.payload
    },
    setFilters(state, action: PayloadAction<{ pageid: number, items: number }>) {
      state.filters = {
        ...state.filters,
        pageid: action.payload.pageid,
        items: action.payload.items
      }
    },
    setHasMore(state, action: PayloadAction<boolean>) {
      state.filters.hasMore = action.payload
    },
    incrementPageid(state, action: PayloadAction<null>) {
      state.filters.pageid = state.filters.pageid + 1
    },
    removeNotificationById(state, action: PayloadAction<string>) {
      const lists = state.data.slice()
      const index = lists.findIndex(o => o.notification_id === action.payload)
      lists.splice(index, 1)
      state.data = lists
    }
  }
})

export const {
  setLoading,
  setStatus,
  setNotifications,
  addNotifications,
  setFilters,
  clearNotifications,
  setHasMore,
  incrementPageid,
  removeNotificationById
} = NotificationsReducer.actions

export default NotificationsReducer.reducer
