import React from 'react'
import cx from 'classnames'

interface Props {
  colSpan?: number,
  clsName?: string,
  className?: string
  style?: any
  raw?: boolean
}

export const Td: React.FC<Props> = ({clsName, raw= false, ...rest}) => {
  return (
      <td {...rest}>
        <div className={cx({'data': !raw}, clsName)}>
          {rest.children}
        </div>
      </td>
  )
}

export default Td
