import request from '.'

// export const fetchIncidents = (params: any) => {
//   return request.get('/ncalls-incidents', {
//     params
//   })
// }

export const fetchIncidentByNumber = (incident_number: string) => {
  return request('/fetch-details', {
    params: {
      'incident-no': incident_number
    }
  })
}

export const addNotes = (data: any) => {
  return request.post('/add-notes', data)
}

export const getClientList = (user_id: string) => {
  return request.get('/services-list', {
    params: {
      user_id: user_id,
      endpoint: 'services'
    }
  })
}

export const getUsersList = (user_id: string) => {
  return request.get('/services-list', {
    params: {
      user_id: user_id,
      endpoint: 'users'
    }
  })
}

export const fetchNotesByIncident = (incident_no: string) => {
  return request.get(`/incidents/notes?incident-no=${incident_no}`)
}

export const fetchIncidentsV2 = (params: any) => {
  return request.get('/incidents/data', {
    params
  })
}

export const fetchIncidentsCount = (params: any) => {
  return request.get(`/incidents/counts`, {
    params
  })
}



