import React from 'react'
import NCContainer from './NCContainer'
import { Col, Row } from '../ui'
import AccessDenied from 'components/ui/icons/access-denied.svg'

interface IUnathorizedPage {

}

const UnauthorizedPage: React.FC<IUnathorizedPage> = (): React.ReactElement => {
  return (
    <NCContainer>
      <Row>
        <Col xs={12}>
          <div className='nc-wrapper'>
            <div className='nc-contentMain full'>
              <div className='page'>
                <div className='nc-noAccess'>
                  <img alt='no-access' src={AccessDenied} />
                  <p>You don’t have access to this page. Access denied! </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </NCContainer>
  )
}

export default UnauthorizedPage