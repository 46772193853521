import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface AuthStateI {
  user: UserI,
  isLoggedIn: boolean
}

interface UserI {
  id?: string
  email?: string
}

let initialState: AuthStateI = {
  user: {},
  isLoggedIn: false
}

const AuthReducerSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserI>) {
      state.user = action.payload
    }
  }
})


export const { setUser } = AuthReducerSlice.actions

export default AuthReducerSlice.reducer
