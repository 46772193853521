import React, { useState } from 'react'
import styled from 'styled-components'
import { getToken } from '../../firebase'
import { useAuth } from 'common/hooks/use-simple-auth'

interface NotificationStatusI {
}

const Section = styled.div`
  position: fixed;
  bottom: 0;
  background: #fff;
  color: blue;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  border-top: 2px solid #dedede;

  .n-close-icon {
    color: black;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

const LinkText = styled.span`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export const NOTIFICATION_STATUS = {
  GRANTED: 'granted',
  DEFAULT: 'default',
  DENIED: 'denied',
}

const NotificationStatus: React.FC<NotificationStatusI> = (props): React.ReactElement | null => {

  const [status, setStatus] = useState<'granted' | 'default' | 'denied'>(Notification.permission)
  const {GRANTED, DENIED} = NOTIFICATION_STATUS
  const {user: {user_id}} = useAuth()

  const openPermissionWindow = () => {
    getToken(user_id, (allowed) => {
      if (allowed) {
        setStatus('granted')
        new Notification('Push Notification Enabled !', {icon: '/favicon.ico'})
      } else {
        setStatus('denied')
      }
    })
  }

  if (status === DENIED || status === GRANTED) {
    return null
  }

  return (
    <Section>
      <span>
        Enable the push notification by
        &nbsp;<LinkText onClick={openPermissionWindow}>clicking here.</LinkText>
      </span> &nbsp; &nbsp;
      <span
        className='n-close-icon'
        onClick={() => setStatus('denied')}
      >X
      </span>
    </Section>
  )
}

export default NotificationStatus