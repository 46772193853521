import axios from 'axios'
import { PUBLIC_URL } from 'common/constants'

const user = JSON.parse(localStorage.getItem('user') || '{}') || {}

// export const BASE_URL = 'https://bgs9040wwi.execute-api.us-east-1.amazonaws.com/dev'
// export const BASE_URL = 'https://pktufjwor0.execute-api.us-east-1.amazonaws.com/'

const request = axios.create({
  baseURL: PUBLIC_URL,
  headers: {
    Email: user && `${user.email}`,
    'X-API-KEY': 'lg69szqD4D8KlXIB8Cu785vng38hUCg761VpD7Us',
  },
})

request.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('user') || '') || {}
  config.headers.Email = user.email || ''
  return config
})

request.interceptors.response.use(res => {
  if (res?.status === 403) {
    window.location.href = '/refresh/roles'
  }
  // Important: response interceptors **must** return the response.
  return res
}, (error) => {
  if (error?.toString()?.includes('code 403')) {
    window.location.href = '/refresh/roles'
  }
  return error
})

export default request


