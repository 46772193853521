import request from '.'

//?user_role=Standard%20User&search=&sorted_by=email_id&sorted_type=decs&pageid=1&items=10
export const fetchUsers = (params: any) => {
  return request.get('/users-roles-listing', {
    params
  })
}

export const fetchUserByEmail = (params: any) => {
  return request.get('/get-team-member', {
    params
  })
}

export const getUserRolesMisc = (params: any) => {
  return request.get('/get-users-misc-info', {
    params
  })
}

export const addTeamMember = (data: any) => {
  return request.post('/update-team-member', data)
}

export const getUsersGroupsLists = (params: any) => {
  return request.get('/groups-access-listing', {
    params
  })
}

export const upsertGroup = (data: any) => {
  return request.post('/update-group', data)
}

export const getGroupByName = (params: any) => {
  return request('/get-group', {
    params
  })
}

export const getUserInfoByEmail = (email: string) => {
  return request(`/get-team-member?email_to_get=${email}`)
}

export const getClients = (params: any) => {
  return request('/client-listing', {
    params
  })
}

export const getClientById = (id: string) => {
  return request('/get-client', {
    params: {
      email_to_get: id
    }
  })
}

export const getClientList = () => {
  return request('/get-client-misc-info?endpoint=companies_list')
}

export const getStatusList = () => {
  return request('/get-client-misc-info?endpoint=status_list')
}

export const getServicesLists = (user_id: string) => {
  return request.get('/services-list', {
    params: {
      user_id: user_id,
      endpoint: 'services'
    }
  })
}

export const upserClient = (payload: any) => {
  return request.post('/update-client', payload)
}
