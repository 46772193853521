import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Notificaiton from './notification'
import { incrementPageid, removeNotificationById } from 'store/reducers/notification.reducer'
import Spin from 'components/nCall/spin'
import { useDispatch } from 'react-redux'
import { HappyEmoji, NoFoundEmoji } from 'components/ui/icons'
import { SeenAll } from 'components/common/styledComponent'

interface INotificationsLists {
  notifications: any[]
  lazyLoader: boolean
  hasMore: boolean
  isOpen: boolean
  isActionPerformed: boolean
  setIsActionPerformed: (isPerformed: boolean) => void
  toggleOpen: () => void
}

export const NoFoundSection = styled.div`
    text-align: center;
    margin-top: 8rem;
`

const NotificationsLists: React.FC<INotificationsLists> = (props): React.ReactElement => {

  const {notifications, hasMore, toggleOpen, isOpen, setIsActionPerformed, isActionPerformed} = props
  const scrollRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  const renderLoader = () => {
    if (!hasMore)
      return (
        <SeenAll><HappyEmoji/>
          <span>Hey, You’ve seen all the
            notifications</span>
        </SeenAll>
      )

    return (
      <div className='nc-notification'>
        {
          hasMore &&
          <div className='lazy-loader-div'>
            <Spin small={true}/>
          </div>
        }
      </div>
    )
  }

  const handleRemove = (id: string) => {
    dispatch(removeNotificationById(id))
  }

  const loadingMore = () => {
    scrollRef?.current?.addEventListener('scroll', () => {
      if (
        (scrollRef?.current?.scrollTop || 0) + (scrollRef?.current?.clientHeight || 0) >=
        (scrollRef?.current?.scrollHeight || 0)
      ) {
        // loadMore()
        console.log('Loading more...')
        dispatch(incrementPageid(null))
      }
    })
  }

  useEffect(() => {
    if (hasMore) {
      loadingMore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore])

  if (!hasMore && notifications.length === 0) {
    return (
      <NoFoundSection>
        <NoFoundEmoji/>
        <h4 style={{lineHeight: '1.5'}}>
          <b> Hey, you’ve got <br/>
            no notifications </b>
        </h4>
      </NoFoundSection>
    )
  }

  return (
    <div ref={scrollRef} className='nc-notifications-lists'>
      {
        notifications.map((n, index) => (
          <Notificaiton
            key={n.notificaiton_id}
            number={index + 1}
            onRemove={handleRemove}
            setIsActionPerformed={setIsActionPerformed}
            isActionPerformed={isActionPerformed}
            isOpen={isOpen}
            notification={n}
            toggleOpen={toggleOpen}
          />
        ))
      }
      {renderLoader()}
    </div>
  )
}

export default NotificationsLists