import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { IncidentsMenuIcon, RunbookMenuIcon, ReportIcon, EscalationIcon, ScheduleMenu, ArrowRightIcon, ReportActiveIcon, IncidentActiveIcon, RunbookActiveIcon, SCheduleActiveIcon, EscalationPolicyActiveIcon } from 'components/ui/icons'
import styled from 'styled-components'
import NCButton from '../../nCall/NCButton'
import {
  // ACCOUNT_OWNER,
  // ADMIN,
  isUserAllowedToAccessEscalationPolicy,
  isUserAllowedToAccessIncidents,
  isUserAllowedToAccessSchedule,
  isUserAllowedToReports, isUserAllowedToViewRunbookLists,
  isUserClient,
  // STANDARD_USER
} from 'common/utils/permissionFunctions'
import { useAuth } from 'common/hooks/use-simple-auth'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { client_params, required_params } from 'store/reducers/incidents.reducer'
import { useSelector } from 'react-redux'
import { RootState } from 'rootReducer'
import './sidebar.css'

const ExpandAction = styled.div<{ isExpanded: boolean }>`
  position:absolute;
  top: 2rem;
  left: ${({ isExpanded }) => isExpanded ? '185px' : '55px'};
  z-index: 2; 
  transition: all .2s ease-in;
  .nc-action-button {
    cursor: pointer;
    background: #fff;
    box-shadow: 0 0 10px 0 #eeeeee;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #727d8c;
      ${({ isExpanded }) => isExpanded ? 'transform: rotate(180deg)' : ''};
    }
  }
`

const UL = styled.ul<{ expand: boolean }>`
    list-style: none;
    padding: 0;
    display: grid;
    li {
      padding: 15px 5px;
      display: flex;
      i {
          background-color: #f5f8fa;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 35px;         
      }
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        span {
          font-size: 14px;
          font-weight: bold;        
          color: #1c2b39;
          margin-left: 15px;  
          display: ${({ expand }) => expand ? 'block' : 'none'}
        }
      }
    }
    li.active {
        i {
          background-color: #0071ea;
          height: 35px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 35px;                   
          svg,g {         
            width: 35px;            
          }        
        }
        a {
          &:hover {
            text-decoration: none;
          }
          span {
            color: #0071ea !important;
          }
        }
      }
`

interface SideBarI {
  expand: boolean
  toggleExpand: any
}

const SideBar: React.FC<SideBarI> = (props): React.ReactElement => {

  const { expand, toggleExpand } = props
  const { location } = window
  const [activeLink, setActiveLink] = useState(location.pathname)
  const { user } = useAuth()
  const runbookFilters = useSelector((state: RootState) => state.runbook.filters)
  const runbookParams = queryString.stringify(runbookFilters)
  const params = queryString.stringify(required_params)
  const clientParams = queryString.stringify(client_params)

  const MENUS = [
    {
      id: 'report',
      title: 'Dashboard',
      activeIcon: <ReportActiveIcon />,
      icon: <ReportIcon />,
      url: '/dashboard',
      active_url: '/dashboard',
      visible: isUserAllowedToReports(user.permissions, [], user.user_role)
    },
    {
      id: 'incidents',
      title: 'Incidents',
      activeIcon: <IncidentActiveIcon />,
      icon: <IncidentsMenuIcon />,
      active_url: '/incidents',
      url: `/incidents?${isUserClient(user.user_role) ? clientParams : params}`,
      visible: isUserAllowedToAccessIncidents(user.permissions, user.user_role)
    },
    {
      id: 'runbooks',
      title: 'Runbooks',
      active_url: '/nc-runbooks',
      activeIcon: <RunbookActiveIcon />,
      icon: <RunbookMenuIcon />,
      url: `/nc-runbooks/runbooks?${runbookParams}`,
      visible: isUserAllowedToViewRunbookLists(user.permissions)
    },
    {
      id: 'on-call-schedule',
      title: 'On-Call Schedule',
      active_url: '/on-call-schedule',
      activeIcon: <SCheduleActiveIcon />,
      icon: <ScheduleMenu />,
      url: '/on-call-schedule',
      visible: isUserAllowedToAccessSchedule(user.user_role)
    },
    {
      id: 'escalation-policy',
      title: 'Escalation Policy',
      active_url: '/nc-escalation-policy',
      activeIcon: <EscalationPolicyActiveIcon />,
      icon: <EscalationIcon />,
      url: '/nc-escalation-policy',
      visible: isUserAllowedToAccessEscalationPolicy(user.user_role)
    },
  ]

  const checkIsActive = (path: string) => {
    return activeLink.includes(path)
  }

  useEffect(() => {
    setActiveLink(location.pathname)
  }, [location.pathname])

  return (
    <div className={cx('sidebar-menu-section', { expand })}>
      <ExpandAction isExpanded={expand}>
        <NCButton onClick={toggleExpand} iconButton={true}>
          <ArrowRightIcon />
        </NCButton>
      </ExpandAction>
      <div className={cx('sidebar-menu', { expand })}>
        <UL expand={expand}>
          {MENUS.filter(o => o.visible).map((menu) => (
            <li
              key={menu.id}
              className={cx('', {
                active: checkIsActive(menu.active_url)
              })
              }
            >
              <Link to={menu.url}>
                <i className='nc-svgIcon navLink-icon'>
                  {checkIsActive(menu.active_url) ? menu.activeIcon : menu.icon}
                </i>
                <span>{menu.title}</span>
              </Link>
            </li>
          ))}
          {/* {renderLinkByUserRole()} */}
        </UL>
      </div>
    </div>
  )
}

export default SideBar