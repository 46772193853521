import { createSlice, PayloadAction } from '@reduxjs/toolkit'


export enum ESCLATION_TYPE {
  USERS = 'users',
  SCHEDULES = 'schedules'
}


export interface IEscalationForm {
  policy_id?: string
  name: string,
  details: string,
  repeat_after: boolean
  repeat_after_minutes: string
  levels: IEscalationLevel[]
  created_at?: string
  updated_at?: string
}
export interface IEscalationLevel {
  id: number
  type: ESCLATION_TYPE
  schedules?: string[]
  users?: string[]
  escalate_after: boolean
  escalate_after_minutes: string
}
export interface escalationPolicyReducer {
  data: any[];
  loading: boolean
  form: IEscalationForm
  repeat_after_min: null | number
}

let intitialLevel: IEscalationLevel = {
  id: Date.now(),
  type: ESCLATION_TYPE.USERS,
  users: [],
  escalate_after: false,
  escalate_after_minutes: ''
}

let initialState: escalationPolicyReducer = {
  data: [],
  loading: false,
  form: {
    name: '',
    details: '',
    repeat_after: false,
    repeat_after_minutes: '',
    levels: [{ ...intitialLevel }]
  },
  repeat_after_min: null
}
const escalationPolicySlice = createSlice({
  name: 'escalation-policy',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setInitialState(state, action: PayloadAction) {
      state.form = { ...initialState.form }
    },
    setFormData(state, action: PayloadAction<any>) {
      state.form = {
        ...state.form,
        ...action.payload
      }
    },
    addLevel(state, action: PayloadAction) {
      state.form.levels = [
        ...state.form.levels,
        { ...intitialLevel, id: Date.now() },
      ]
    },
    removeLevel(state, action: PayloadAction<number>) {
      let _levels = state.form.levels.slice()
      const idx = _levels.findIndex((l: any) => l.id === action.payload)
      _levels.splice(idx, 1)
      state.form.levels = _levels
    },
    handleChangeOnLevel(state, action: PayloadAction<{ id: number, data: any }>) {
      let _levels = state.form.levels.slice()
      const idx = _levels.findIndex((l: any) => l.id === action.payload.id)
      _levels[idx] = { ..._levels[idx], ...action.payload.data }
      state.form.levels = _levels
    }
  }
})

export const {
  setLoading,
  setFormData,
  addLevel,
  removeLevel,
  handleChangeOnLevel,
  setInitialState
} = escalationPolicySlice.actions

export default escalationPolicySlice.reducer
