import { CopyIcon } from 'components/ui/icons'
import styled from 'styled-components'

export const DInlineCenter = styled.div`
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
`

export const LoaderContainer = styled.div`
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const SpinContainer = styled.div`
  height: 100%;
  width: 'inherit';
  display: flex;
  justify-content: center;
  align-items: center;
`
export const DFlexCenter = styled.div`
  display: flex;
  align-items: center;
`
export const DFlexBase = styled.div`
  display: flex;
  align-items: baseline;
`
export const DFlexBaseBetween = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`

export const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const FlexCenterBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CopyIconSvg = styled(CopyIcon)`
  height: 16px;
  fill: #8a94a0;
`
export const LinkImage = styled.img`
  width: 80%;
  height: fit-content;
  margin-top: 15px;
`
export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Links = styled.span`
  margin-right: 10px;
  font-size: 15px;
}`

export const LinkSection = styled.p`
  margin-top: 10px !important;
`

export const LinkButton = styled.div<{ noBold?: boolean }>`
  font-size: 14px;
  ${({ noBold }) => !noBold && 'font-weight: bold'};
  color: #0071ea;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  
  svg {
    fill: #0071ea;
  }
`

export const SuccessText = styled.span`
  color: #06ac38;
  font-weight: bold;
}`

export const DFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 10px;
    margin-top: 10px;
  }
`
export const DStartAlignEnd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const FlexAlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SeenAll = styled.p`
    color: #1c2b39;
    font-weight: 700;   
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #0071ea;
      height: 65px;
      width: 23px;
    }
    span {
      margin-left: 10px;
    }
`
export const NCFilterSection = styled.div`
  display: flex;
  height: 80px;
  border-radius: 4px;
  padding: 15px;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  border: 1px solid #eeeaea;
  .ncc-input-group {
    margin-bottom: 0;
    width: 550px;
  }
`

export const AddMoreButton = styled.button`
  background: #0071ea;
  color: #fff;
  border: 0;
  border-radius: 50%;
  font-size: 18px;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
}

`

export const ExpandCollapseIcon = styled.i<{ isExpanded: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ isExpanded }) => isExpanded ? 'white' : '#727d8c'};
    ${({ isExpanded }) => isExpanded ? 'transform: rotate(270deg);' : ''}
  }
`
