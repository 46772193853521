import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import Spin from 'components/nCall/spin'
import { AlertNotificationIcon } from 'components/ui/icons'
import NotificationsLists from './notifications-lists'
import { RootState } from 'rootReducer'
import {
  setNotifications,
  addNotifications,
  clearNotifications,
  setFilters,
  setHasMore
} from 'store/reducers/notification.reducer'
import { fetchNotifications } from 'services/notification.service'
import './app-notification.css'

const LoaderSection = styled.p`
  display: flex;
  height: 350px;
  justify-content: center;
  align-items: center;
`

const NCount = styled.div`
    position: absolute;
    top: -4px;
    right: 0px;
    font-size: 14px;
    color: #fff;
    line-height: 1;
    font-weight: bolder;
    background: red;
    border-radius: 33px;
    padding: 2px;
`

interface IAppNotification {
  onRefresh: () => void
}

const AppNotification: React.FC<IAppNotification> = ({onRefresh}): React.ReactElement => {

  const {data, filters, status} = useSelector((state: RootState) => state.notifications)
  const [initialLoading, setInitialLoading] = useState(false)
  const [lazyLoader, setLazyLoader] = useState(false)
  const [open, setOpen] = useState(false)
  const [isActionPerformed, setIsActionPerformed] = useState(false)
  const dispatch = useDispatch()

  const toggleOpen = () => {
    setOpen(!open)
    setIsActionPerformed(false)
    if (isActionPerformed && open) {
      //reload incidents
      onRefresh()
      console.log('reLoading...')
    }
  }

  const handleCloseNotification = () => {
    setOpen(false)
    setIsActionPerformed(false)
    if (isActionPerformed && open) {
      onRefresh()
      console.log('refreshing...')
    }
  }

  const renderNotificationLists = () => {

    if (initialLoading) {
      return <LoaderSection><Spin/></LoaderSection>
    }

    return (
      <NotificationsLists
        toggleOpen={toggleOpen}
        isOpen={open}
        notifications={data}
        isActionPerformed={isActionPerformed}
        setIsActionPerformed={setIsActionPerformed}
        lazyLoader={lazyLoader}
        hasMore={filters.hasMore}
      />
    )
  }

  useEffect(() => {
    if (open && filters.hasMore) {
      if (data.length === 0) {
        setInitialLoading(true)
      } else {
        setLazyLoader(true)
      }
      fetchNotifications({
        pageid: filters.pageid,
        items: filters.items
      }).then((res) => {
        if (res?.data?.data?.length < filters.items) {
          dispatch(setHasMore(false))
        }
        if (data.length === 0) {
          dispatch(setNotifications(res?.data?.data || []))
        } else {
          dispatch(addNotifications(res?.data?.data || []))
        }
      }).catch((err) => {
        console.log('err', err)
      }).finally(() => {
        setInitialLoading(false)
        setLazyLoader(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, filters])

  useEffect(() => {
    if (!open) {
      dispatch(setFilters({pageid: 1, items: filters.items}))
      dispatch(setHasMore(true))
      dispatch(clearNotifications(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <OutsideClickHandler onOutsideClick={handleCloseNotification}>
      <div className='nc-app-notification'>
        <span onClick={toggleOpen}>
          <i className='alert-icon-svg'>
            <AlertNotificationIcon/>
          </i>
          {
            status.notification_count > 0 &&
            <NCount>{status.notification_count}</NCount>
          }
        </span>
        {
          open &&
          <div className='nc-notification-section'>
            <h3>Notifications</h3>
            {renderNotificationLists()}
          </div>
        }
      </div>
    </OutsideClickHandler>
  )
}

export default AppNotification