/*
Default Roles const
 */

export const ACCOUNT_OWNER = 'Account Owner'
export const ADMIN = 'Admin'
export const CLIENT_ADMIN = 'Client Admin'
export const CLIENT_READ_ONLY = 'Client Read Only'
export const STANDARD_USER = 'Standard User'
export const READ_ONLY = 'Read Only'


/*
Only can View the incidents Page
Can see details of
Timeline
Can select client list(Incident Tab).
 */
export const NCALLS_INCIDENTS = 'ncalls-incidents'

/*
Can View Runbook
Runbook Listing
*/
export const GET_RUNBOOK = 'get-runbook'

/* Add/edit/delete:Delete users (cannot delete the Account Owner)
Visible to Admin or Owner Access or with this permission itself provided by Admin */
export const UPDATE_TEAM_MEMBER = 'update-team-member'

export const SERVICE_LIST = 'services-list' // View Clients

/*  ADD_RUNBOOK
Can View Runbook
Runbook Listing
Edit Runbook(Full Runbook Access)
*/
export const ADD_RUNBOOK = 'add-runbook' // View runbooks & Full runbook access

// Perform Action on incidents(Acknowledge, Resolve,Snooze,Reassign and Add Note)
export const ADD_NOTES = 'add-notes'

// Can see only the List of Runbooks
export const RUNBOOK_LITSING = 'runbook-listing'

export const VIEW_CLIENT_ANALYTICS = 'qs-client-embed-url'
export const VIEW_TEAM_ANALYTICS = 'qs-team-embed-url'
export const VIEW_USERS_ANALYTICS = 'qs-users-embed-url'
export const VIEW_STATUS_ANALYTICS = 'qs-status-embed-url'


export const getIncidentsPermissions = () => {
  return [
    NCALLS_INCIDENTS
  ]
}

export const getAllRoles = () => {
  return [
    READ_ONLY,
    STANDARD_USER,
    CLIENT_READ_ONLY,
    CLIENT_ADMIN,
    ADMIN,
    ACCOUNT_OWNER
  ]
}

export const getRunbooksListsAccessPermission = () => {
  return [
    GET_RUNBOOK,
    ADD_RUNBOOK,
    RUNBOOK_LITSING
  ]
}

export const getRunbookEditPermission = () => {
  return [
    ADD_RUNBOOK
  ]
}

export const getRunbookViewPermission = () => {
  return [
    GET_RUNBOOK,
    ADD_RUNBOOK
  ]
}

export const isUserAllowedToAccessIncidents = (usersPermission: string[] = [], userRoles: string[]) => {
  return usersPermission.includes(NCALLS_INCIDENTS) ||
    userRoles.includes(CLIENT_READ_ONLY) ||
    userRoles.includes(CLIENT_ADMIN)
}

// this function will used in routes
export const checkifUserAllowedToAccess =
  (requiredPermission: string[] = [],
    usersPermissions: string[] = [],
    userRole?: string[],
    requiredUserRole?: string[]
  ) => {
    let isAllowed = false
    requiredPermission.forEach(rp => {
      if (usersPermissions.includes(rp)) {
        isAllowed = true
      }
    })

    if (userRole && requiredUserRole) {
      requiredUserRole.forEach(role => {
        if (userRole.includes(role)) {
          isAllowed = true
        }
      })
    }
    return isAllowed
  }

export const checkIfUserAllowForTeamAndRole =
  (user_role: string[] = [], permissions: string[] = []): boolean => {

    return user_role.includes(ADMIN) ||
      user_role.includes(ACCOUNT_OWNER)
  }


export const isUserAllowedToClientFilter = (userPermissions: string[]) => {
  return userPermissions.includes(SERVICE_LIST)
}

export const isUserAllowedToAddNotes = (userPermissions: string[]) => {
  return userPermissions.includes(ADD_NOTES)
}

export const isUserAllowedToEditRunbook = (userPermissions: string[]) => {
  return userPermissions.includes(ADD_RUNBOOK)
}

export const isUserAllowedToViewRunbook = (userPermission: string[]) => {
  return userPermission.includes(ADD_RUNBOOK) ||
    userPermission.includes(GET_RUNBOOK)
}

export const isUserAllowedToViewRunbookLists = (userPermissions: string[] = []) => {
  return userPermissions.includes(GET_RUNBOOK) ||
    userPermissions.includes(ADD_RUNBOOK) ||
    userPermissions.includes(RUNBOOK_LITSING)
}

export const isUserAllowedToAccessSchedule = (roles: string[]) => {
  return roles.includes(ADMIN) || roles.includes(ACCOUNT_OWNER)
}

export const isUserAllowedToAccessEscalationPolicy = (roles: string[]) => {
  return roles.includes(ADMIN) || roles.includes(ACCOUNT_OWNER)
}

export const isUserAllowedToEditUsers =
  (
    userPermissions: string[], // loggedinUser permission
    user_role: string[], // loggedin User roles
    currentRole: string[] // current user data records role
  ) => {
    if (currentRole.includes(ACCOUNT_OWNER)) {
      return false
    } else if (currentRole.includes(ADMIN) && user_role.includes(ADMIN)) {
      return false
    } else if (currentRole.includes(ADMIN) && !user_role.includes(ACCOUNT_OWNER)) {
      return false
    } else {
      return true
    }
  }

export const isUserAllowedToReports =
  (
    userPermissions: string[], // loggedinUser permission
    user_role: string[], // loggedin User roles
    currentRole: string[]
  ) => {
    return currentRole.includes(ACCOUNT_OWNER) ||
      currentRole.includes(ADMIN) ||
      currentRole.includes(STANDARD_USER) ||
      currentRole.includes(CLIENT_ADMIN) ||
      currentRole.includes(CLIENT_READ_ONLY) ||
      currentRole.includes(READ_ONLY) ||
      userPermissions.includes(VIEW_CLIENT_ANALYTICS) ||
      userPermissions.includes(VIEW_STATUS_ANALYTICS) ||
      userPermissions.includes(VIEW_TEAM_ANALYTICS) ||
      userPermissions.includes(VIEW_USERS_ANALYTICS)
  }

export const isUserAllowedToClientReports = (
  userPermissions: string[], // loggedinUser permission
  user_role: string[] // loggedin User roles
) => {
  return userPermissions.includes(VIEW_CLIENT_ANALYTICS) ||
    user_role.includes(ACCOUNT_OWNER)
}

export const isUserAllowedToClients = (
  userPermissions: string[], // logged-in User permission
  user_role: string[] // logged-in User roles
) => {
  return user_role.includes(ADMIN) ||
    user_role.includes(ACCOUNT_OWNER)
}

export const isUserAllowedToExtensions = (
  userPermissions: string[], // logged-in User permission
  user_role: string[] // logged-in User roles
) => {
  return user_role.includes(ADMIN) ||
    user_role.includes(ACCOUNT_OWNER)
}

export const isUserAllowedToDashboardClientFilter = (
  userPermissions: string[], // loggedinUser permission
  user_role: string[] // loggedin User roles
) => {

  if (user_role.includes(CLIENT_ADMIN) || user_role.includes(CLIENT_READ_ONLY)) {
    return false
  }

  return user_role.includes(ADMIN) ||
    user_role.includes(ACCOUNT_OWNER) ||
    user_role.includes(STANDARD_USER) ||
    userPermissions.includes(VIEW_CLIENT_ANALYTICS)
}

export const isUserClient = (roles: string[]) => {
  return roles.includes(CLIENT_ADMIN) || roles.includes(CLIENT_READ_ONLY)
}

export const isClientAdmin = (roles: string[]) => {
  return roles.includes(CLIENT_ADMIN)
}

export const isItDefaultRole = (id: any) => {
  return id === CLIENT_READ_ONLY ||
    id === CLIENT_ADMIN ||
    id === ACCOUNT_OWNER ||
    id === ADMIN ||
    id === READ_ONLY ||
    id === STANDARD_USER
}