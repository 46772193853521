import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import _get from 'lodash/get'
import _unset from 'lodash/unset'
import _isEmpty from 'lodash/isEmpty'
import {
  IRunbook, IRunbookAlertAndResolution, IRunbookLists,
  MODE, IIntegratedDocs, IDocument
} from '../store.types'
import uuid from 'uuid'
import { bindColumnWidth, processParams } from 'common/utils/helperFunction'
import { ColumnType } from 'rc-table/es/interface'
import moment from 'moment'

export const RUNBOOKS_TAB = 'runbooks'
export const INTEGRATED_DOCS_TAB = 'integrated-docs'

export const RUNBOOK_TABS = [
  {key: 'runbooks', className: 'red', title: 'Runbooks', count: 0},
  {key: 'integrated-docs', className: 'yellow', title: 'Integrated Docs', count: 0},
]

export const RUNBOOK_COLUMNS: ColumnType<IRunbookAlertAndResolution>[] &
  { order?: number, visible: boolean, default: boolean }[] = [
  {
    title: 'Alert',
    key: 'alert',
    dataIndex: 'alert',
    fixed: 'left',
    visible: true,
    default: true,
    readOnly: true,
    order: 0,
    width: 200
  },
  {
    title: 'Cause',
    key: 'cause',
    dataIndex: 'cause',
    fixed: 'left',
    visible: true,
    default: true,
    readOnly: true,
    order: 1,
    width: 200
  },
  {
    title: 'Remediation',
    key: 'remediation',
    dataIndex: 'remediation',
    visible: true,
    default: true,
    readOnly: true,
    order: 2,
    width: 200
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    visible: true,
    default: true,
    readOnly: true,
    order: 3,
    width: 200
  },
  {
    title: 'Priority',
    key: 'priority',
    dataIndex: 'priority',
    visible: false,
    default: true,
    readOnly: false,
    order: 4,
    width: 200
  },
  {
    title: 'Custom_Col_1',
    key: 'custom_col_1',
    dataIndex: 'custom_col_1',
    visible: false,
    default: false,
    readOnly: false,
    order: 5,
    width: 200
  },
  {
    title: 'Custom_Col_2',
    key: 'custom_col_2',
    dataIndex: 'custom_col_2',
    visible: false,
    default: false,
    readOnly: false,
    order: 6,
    width: 200
  },
  {
    title: 'Custom_Col_3',
    key: 'custom_col_3',
    dataIndex: 'custom_col_3',
    visible: false,
    default: false,
    readOnly: false,
    order: 7,
    width: 200
  },
  {
    title: 'Custom_Col_4',
    key: 'custom_col_4',
    dataIndex: 'custom_col_4',
    visible: false,
    default: false,
    readOnly: false,
    order: 8,
    width: 200
  },
  {
    title: 'Custom_Col_5',
    key: 'custom_col_5',
    dataIndex: 'custom_col_5',
    visible: false,
    default: false,
    readOnly: false,
    order: 9,
    width: 200
  }
]

export const DEFAULT_RUNBOOK_DATA: IRunbook = {
  service_id: '',
  status: '',
  alerts_n_resolution: [
    {
      id: uuid(),
      alert: '',
      business_impact: '',
      cause: '',
      priority: '',
      remediation: '',
      tags: [],
      custom_col_1: '',
      custom_col_2: '',
      custom_col_3: '',
      custom_col_4: '',
      custom_col_5: '',
      timestamp: moment().unix().toString(),
      index: 0
    },
  ],
  last_updated: null,
  columns: RUNBOOK_COLUMNS,
  total_count: 1,
  version_id: '',
  service_name: '',
}


export type RunbookReducerState = {
  runbooks: IRunbookLists[]
  draft_count: number
  published_count: number
  total_count: number
  integrated_docs: {
    docs: IIntegratedDocs[],
    total_count: number
    filters: {
      pageid: number,
      items: number,
      search: string,
      sorted_type: string
      sorted_by: string
    }
    document: IDocument
  }
  filters: {
    pageid: number
    items: number
    search: string
    sorted_type: string
    sorted_by: string
    status: string
    clients_id: string
  },
  activeTab: string
  tags: string[]
  tabs: any[]
  runbookEditor: IRunbook
  runbookFilter: {
    pageid: number,
    items: number
  }
  import: {
    client: string,
    data: any[]
    show: boolean
    file: any | null
  }
  loading: boolean
  mode: MODE
  total: number
}

const runbook_row = {
  id: uuid(),
  alert: '',
  business_impact: '',
  cause: '',
  priority: '',
  remediation: '',
  custom_col_1: '',
  custom_col_2: '',
  custom_col_3: '',
  custom_col_4: '',
  custom_col_5: '',
  tags: [],
  timestamp: moment().unix().toString()
}

export const initialRunbookFilters = {
  pageid: 1,
  items: 0,
  search: '',
  sorted_by: '',
  sorted_type: '',
  clients_id: '',
  status: ''
}
export const required_filters = {
  pageid: 1,
  items: 10,
  search: '',
  sorted_by: '',
  sorted_type: '',
  clients_id: '',
  status: ''
}

export const initialDocumentFilters = {
  items: 0,
  pageid: 1,
  search: '',
  sorted_by: '',
  sorted_type: ''
}

export const requiredDocumentFilters = {
  items: 10,
  pageid: 1,
  search: '',
  sorted_by: '',
  sorted_type: ''
}


const empty_doc: IDocument = {
  action_type: 'add',
  author: '',
  created_at: '',
  customer_account_id: 0,
  description: '',
  document_content: '',
  document_id: '',
  document_length: 0,
  document_link: '',
  document_name: '',
  service_id: '',
  service_name: '',
  status: '',
  updated_at: '',
  updated_by: ''
}

let initialState: RunbookReducerState = {
  runbooks: [],
  draft_count: 0,
  published_count: 0,
  total_count: 0,
  integrated_docs: {
    docs: [],
    total_count: 0,
    document: empty_doc,
    filters: initialDocumentFilters
  },
  filters: initialRunbookFilters,
  tags: [],
  activeTab: 'runbooks',
  tabs: RUNBOOK_TABS,
  runbookEditor: DEFAULT_RUNBOOK_DATA,
  mode: MODE.EDIT,
  loading: false,
  runbookFilter: {
    pageid: 1,
    items: 50,
  },
  total: 0,
  import: {
    client: '',
    show: false,
    data: [],
    file: null
  }
}

const RunbookListSlice = createSlice({
  name: 'Runbook',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    addRowToRunbookTable(state, action: PayloadAction<null>) {
      const rows = state.runbookEditor.alerts_n_resolution.slice()
      state.runbookEditor.alerts_n_resolution = [...rows, {...runbook_row, index: rows.length}]
    },
    handleRowChange(state, action: PayloadAction<{ index: number, data: any }>) {
      _set(state, `runbookEditor.alerts_n_resolution[${action.payload.index}]`, action.payload.data)
    },
    changeRunbookClient(state, action: PayloadAction<{ name: string, value: any }>) {
      state.runbookEditor.service_id = action.payload.value
    },
    onCustomSectionChange(state, action: PayloadAction<{ index: number, text: string }>) {
      const {text, index} = action.payload
      _set(state, `runbookEditor.custom[${index}].text`, text)
    },
    onRemoveRow(state, action: PayloadAction<number>) {
      const newArr = state.runbookEditor.alerts_n_resolution.slice()
      newArr.splice(action.payload, 1)
      state.runbookEditor.alerts_n_resolution = newArr.map((d, index) => ({ ...d, index }))
    },
    setRunbook(state, action: PayloadAction<IRunbook>) {
      if (_isEmpty(action.payload)) {
        state.runbookEditor = {
          ...state.runbookEditor,
          alerts_n_resolution: []
        }
      } else {
        const columns = bindColumnWidth(action.payload.columns || [])
        state.runbookEditor = {
          ...state.runbookEditor,
          ...action.payload,
          alerts_n_resolution: action.payload.alerts_n_resolution.map((a, i) => ({
            ...a,
            index: i,
            id: i.toString()
          })),
          columns: columns,
        }
      }
      // const columns = bindColumnWidth(action.payload.columns || [])
      // state.runbookEditor = {
      //   ...state.runbookEditor,
      //   ...action.payload,
      //   columns: columns
      // }
    },
    setRunbookFilters(state, action: PayloadAction<any>) {
      state.filters = {...action.payload}
    },
    storeRunbooks(state, action: PayloadAction<any>) {
      state.runbooks = action.payload.versions
      state.total_count = action.payload.total_count || 1
      // state.draft_count = action.payload.draft_count
      // state.published_count = action.payload.published_count
    },
    setEmptyRunbook(state, action: PayloadAction<null>) {
      state.runbookEditor = DEFAULT_RUNBOOK_DATA
    },
    setRunbookTags(state, action: PayloadAction<string[]>) {
      state.tags = action.payload
    },
    setRunbookViewFilters(state, action: PayloadAction<any>) {
      const _payload = processParams(action.payload)
      if (!_payload.keys_search) {
        _payload.keys_search = []
      }
      if (!_payload.values_search) {
        _payload.values_search = []
      }
      if (!_payload.tags_search) {
        _payload.tags_search = []
      }
      state.runbookFilter = {
        ...state.runbookFilter,
        ..._payload
      }
    },
    setColumnVisibility(state, action: PayloadAction<{ checked: boolean, name: string }>) {
      const index =
        state.runbookEditor.columns.findIndex((c) => c.dataIndex === action.payload.name)
      if (index !== -1) {
        state.runbookEditor.columns[index].visible = action.payload.checked
      }
    },
    reorderColumns(state, action: PayloadAction<any[]>) {
      state.runbookEditor.columns = action.payload
    },
    appendUnusedColumns(state, action: PayloadAction<any[]>) {
      // const newColumns = [...state.runbookEditor.columns, ...action.payload]
      state.runbookEditor.columns = [...state.runbookEditor.columns, ...action.payload]
    },
    renameColumn(state, action: PayloadAction<{ key: string, name: string }>) {
      const {key, name} = action.payload
      const index = state.runbookEditor.columns.findIndex(c => c.dataIndex === key)
      if (index !== -1) {
        state.runbookEditor.columns[index].title = name
        state.runbookEditor.columns[index].key = name.toLowerCase()
        state.runbookEditor.columns[index].dataIndex = name.toLowerCase()

        const alertAndResolution = state.runbookEditor.alerts_n_resolution.slice()
        state.runbookEditor.alerts_n_resolution =
          alertAndResolution.map((s) => {
            _set(s, name.toLowerCase(), _get(s, key))
            _unset(s, key)
            return s
          })
        /// change object name
      }
    },
    setIntegratedDocsFilter(state, action: PayloadAction<any>) {
      state.integrated_docs.filters = {...action.payload}
    },
    setIntegratedDocsLists(
      state, action: PayloadAction<{ data: IIntegratedDocs[], total_count: number }>
    ) {
      state.integrated_docs.docs = action.payload.data
      state.integrated_docs.total_count = action.payload.total_count
    },
    setDocument(state, action: PayloadAction<IDocument>) {
      state.integrated_docs.document = action.payload
    },
    setEmptyDoc(state, action: PayloadAction<null>) {
      state.integrated_docs.document = empty_doc
    },
    onDocumentChange(state, action: PayloadAction<{ name: string, value: string }>) {
      const {name, value} = action.payload
      state.integrated_docs.document = {
        ...state.integrated_docs.document,
        [name]: value
      }
    },
    toggleRunbookEnable(state, action: PayloadAction<string>) {
      const _runbooks = state.runbooks.slice()
      const index = _runbooks.findIndex(o => o.service_id === action.payload)
      const runbook: any = _runbooks.find(o => o.service_id === action.payload)
      if (runbook) {
        runbook.enabled = runbook.enabled === 1 ? 0 : 1
      }
      _runbooks.splice(index, 1, runbook)
      state.runbooks = _runbooks
    },
    updateColumnName(state, action: PayloadAction<{ from: string, to: string, isAdding?: boolean }>) {
      const {from, to} = action.payload
      const _columns = state.runbookEditor.columns.slice()
      const index = _columns.findIndex((c: any) => c.dataIndex.toLowerCase() === from.toLowerCase())
      if (index > -1) {
        _columns[index].dataIndex = to.toLowerCase()
        _columns[index].key = to.toLowerCase()
        _columns[index].title = to
        _columns[index].visible = true
        state.runbookEditor.columns = _columns
      }
    },
    setInitialFilters(state, action: PayloadAction<null>) {
      state.filters = initialRunbookFilters
    },
    setInitialRunbookFilters(state, action: PayloadAction) {
      state.runbookFilter = {
        pageid: 1,
        items: 50
      }
    },
    updateColumns(state, action: PayloadAction<any[]>) {
      state.runbookEditor.columns = action.payload
    },
    removeKeyData(state, action: PayloadAction<string>) {
      let data = state.runbookEditor.alerts_n_resolution.slice()
      data = data.map((d) => ({
        ...d,
        [action.payload]: ''
      }))
      state.runbookEditor.alerts_n_resolution = data
    },
    setImportData(state, action: PayloadAction<any>) {
      const newImport = {
        ...state.import,
        ...action.payload
      }
      state.import = newImport
    },
    setInitialImport(state, action: PayloadAction) {
      state.import = {
        client: '',
        show: false,
        data: [],
        file: null
      }
    },
    setRunbookDataForPreview(state, action: PayloadAction<any>) {
      state.runbookEditor = {
        ...state.runbookEditor,
        ...action.payload,
        alerts_n_resolution:
          action.payload.alerts_n_resolution.map((d: IRunbookAlertAndResolution, index: number) => ({...d, index}))
      }
    },
    updateTotalCount(state, action: PayloadAction<number>) {
      state.runbookEditor.total_count = action.payload
    },
    setRunbookPaginationToEnd(state, action: PayloadAction) {
      state.runbookFilter = {
        ...state.runbookFilter,
        pageid: Math.ceil(state.runbookEditor.total_count / state.runbookFilter.items)
      }
    }
  }
})

export const {
  setLoading,
  addRowToRunbookTable,
  handleRowChange,
  changeRunbookClient,
  onRemoveRow,
  setRunbook,
  setEmptyRunbook,
  storeRunbooks,
  setRunbookFilters,
  setRunbookTags,
  setRunbookViewFilters,
  setColumnVisibility,
  reorderColumns,
  renameColumn,
  setIntegratedDocsLists,
  setIntegratedDocsFilter,
  setDocument,
  setEmptyDoc,
  onDocumentChange,
  updateColumnName,
  setInitialFilters,
  appendUnusedColumns,
  updateColumns,
  removeKeyData,
  setImportData,
  setInitialImport,
  setRunbookDataForPreview,
  updateTotalCount,
  setInitialRunbookFilters,
  setRunbookPaginationToEnd
} = RunbookListSlice.actions

export default RunbookListSlice.reducer