import cx from 'classnames'
import React from 'react'

interface SpinProps {
  small?: boolean;
  show?: boolean;
  color?: string
}

export const Spin: React.FC<SpinProps> = ({ show, small, color }) => {
  const spinClasses = cx('nc-loader', {
    show: show,
    small: small,
    color: color
  })

  return (
    <div className={spinClasses}>
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  )
}

Spin.defaultProps = {
  show: true,
  small: false,
}

export default Spin
