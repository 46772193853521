import { useContext } from 'react'
import { RootContext } from 'common/contexts/RootAuthContext'

export const useAuth = () => {
  const context = useContext<any>(RootContext)

  if (context === undefined) {
    throw new Error(
      '`useAuth` hook must be used within a `AuthProvider` component'
    )
  }
  return context
}
